<template>
  <div class="commission_rule">
    <div class="content">
      <p>1、推广员直接推广商品成功后，获得对应等级的佣金收入；</p>
      <p>
        2、根据推广员直接推广商品的累计实际购买金额计算为推广业绩，按照业绩评定推广员等级；
      </p>
      <p>
        3、推广佣金由基础佣金和等级佣金两部分组成，基础佣金按照商品成交价扣减商品成本及平台成本后的百分比计算；
      </p>
      <div v-html="info"></div>
      <p>
        4、自推广员达到等级对应的推广业绩要求起，按该等级标准计算佣金，在达到之前的佣金按前一级的标准计算(退货退款商品除外)；
      </p>
      <p>
        5、推广业绩以一年为期限，超过一年由甲方评定推广员是否继续保持该推广等级。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "commission-rule",

  data() {
    return {
      info: "",
    };
  },

  mounted() {
    this.getCommissionRuleDetails();
  },

  methods: {
    async getCommissionRuleDetails() {
      const data = await this.$API.user.commissionRuleDetails({
        object: {},
      });
      this.info = data.data.data[0].content;
    },
  },
};
</script>

<style lang="scss" scoped>
.commission_rule {
  padding-top: 46px;
  .content {
    padding: 30px 15px;
    background: #fff;
    p {
      color: #666666;
      margin-bottom: 24px;
    }
  }
}
</style>
